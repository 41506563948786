import "./styles.css"

import React, { useRef, useEffect } from "react"
import MenuBtn from "../components/menu-btn"
import Menu from "../components/menu"

export default () => {
  const ref = useRef()

  useEffect(() => {
    window &&
      import("../components/init-mob").then(({ init }) => init(ref.current))
  }, [ref])

  return (
    <div className="mob-container">
      <canvas ref={ref} className="canvas" />
      <Menu />
      <MenuBtn />
    </div>
  )
}
