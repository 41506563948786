import React, { useState, useRef, useEffect } from "react"
import { timer } from "rxjs"
import animate from "animejs"

const images = [
  "/logos/btc.svg",
  "/logos/dazn.svg",
  "/logos/lucky-labs.svg",
  "/logos/grape.svg",
  "/logos/knab.svg",
  "/logos/betsson.svg",
]

export default () => {
  const [curIdx, setCurIdx] = useState(0)
  const curImg = useRef()
  const nextImg = useRef()

  useEffect(() => {
    const sub = timer(1000).subscribe(() => {
      animate({
        targets: curImg.current,
        translateX: "10vw",
        opacity: 0,
        duration: 500,
        easing: "easeInOutQuad",
      })
      animate({
        targets: nextImg.current,
        translateX: "0",
        opacity: 1,
        duration: 500,
        easing: "easeInOutQuad",
        complete: () => {
          if (curImg.current && nextImg.current) {
            curImg.current.style.transform = "translateX(0)"
            curImg.current.style.opacity = 1
            nextImg.current.style.transform = "translateX(-10vw)"
            nextImg.current.style.opacity = 0
          }
          setCurIdx(curIdx < images.length - 1 ? curIdx + 1 : 0)
        },
      })
    })

    return () => sub.unsubscribe()
  }, [curIdx])

  return (
    <div>
      <img
        className="exp-logo cur"
        ref={curImg}
        src={images[curIdx]}
        alt={images[curIdx]}
      />
      <img
        className="exp-logo next"
        ref={nextImg}
        src={images[curIdx + 1] || images[0]}
        alt={images[curIdx]}
      />
    </div>
  )
}
