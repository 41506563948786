import React from "react"

export default () => (
  <div className="contact-btns">
    <a
      className="contact-btn instagram"
      href="https://www.instagram.com/tem_rysh"
      target="_blank"
    ></a>
    <a
      className="contact-btn twitter"
      href="https://twitter.com/tem_rysh"
      target="_blank"
    ></a>
    <a
      className="contact-btn gmail"
      href="mailto:artem.gavrysh@gmail.com"
      target="_blank"
    ></a>
    <a
      className="contact-btn linkedin"
      href="https://www.linkedin.com/in/gavrysh"
      target="_blank"
    ></a>
  </div>
)
