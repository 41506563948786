import "./reset.css"
import "./styles.css"

import React, { Fragment } from "react"
import { isMobile } from "react-device-detect"

import SEO from "../seo/index"
import Desk from "../desk/index"
import Mob from "../mob/index"
import Preloader from "../components/preloader"
import { useStream } from "../components/utils"
import { hidePreloader$ } from "../components/progress"

// disable hot reloader
// if (module.hot) {
//   module.hot.addStatusHandler(status => {
//     if (status === "apply") window.location.reload()
//   })
// }

export default () => {
  const [hidePreloader] = useStream(hidePreloader$)
  return (
    <Fragment>
      <SEO />
      {isMobile ? <Mob /> : <Desk />}
      {hidePreloader || <Preloader />}
    </Fragment>
  )
}
