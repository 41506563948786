import React, { useRef, useEffect } from "react"
import { of } from "rxjs"
import { delay, takeUntil, tap, switchMap, skipUntil } from "rxjs/operators"
import animate from "animejs"

import { zoomIn$, zoomOut$, initZoom$ } from "./streams"
import ExpLogos from "./exp-logos"

export default ({ label, position }) => {
  const ref = useRef()

  useEffect(() => {
    const unsub1 = zoomIn$
      .pipe(
        skipUntil(initZoom$),
        switchMap(() =>
          of(1).pipe(
            delay(1500),
            takeUntil(zoomOut$),
            tap(() => {
              animate({
                targets: ref.current,
                opacity: 1,
                translateX: ref.current.classList.contains("left") ? 30 : -30,
                duration: 1200,
                easing: "easeOutQuad",
                begin: () => {
                  ref.current.style.display = "block"
                },
              })
            })
          )
        )
      )
      .subscribe()

    const unsub2 = zoomOut$
      .pipe(
        skipUntil(initZoom$),
        delay(100),
        tap(() => {
          animate({
            targets: ref.current,
            opacity: 0,
            translateX: 0,
            duration: 300,
            easing: "easeOutQuad",
            complete: () => {
              ref.current.style.display = "none"
            },
          })
        })
      )
      .subscribe()

    return () => {
      unsub1.unsubscribe()
      unsub2.unsubscribe()
    }
  }, [ref])

  return (
    <div ref={ref} className={`section-label ${position}`}>
      {label
        .split("\n")
        .map(txt =>
          txt === "exp-logos" ? (
            <ExpLogos key="exp-logos" />
          ) : (
            <div key={txt}>{txt}</div>
          )
        )}
    </div>
  )
}
