import React, { Fragment } from "react"

import Label from "./label"
import { useStream } from "./utils"
import { labelActive$ } from "./streams"
import { sections } from "./sections-meta"

const getPosByIdx = idx => {
  if (idx === 0) return "top left"
  if (idx === 1) return "top right"
  if (idx === 2) return "bottom left"
  if (idx === 3) return "bottom right"
}

export default () => {
  const [activeLabel] = useStream(labelActive$)
  return (
    <Fragment>
      {(sections[activeLabel] || []).map((label, idx) => (
        <Label key={idx} label={label} position={getPosByIdx(idx)} />
      ))}
    </Fragment>
  )
}
