import React from "react"

import { quality$, fullscreen$ } from "./streams"
import { useStream } from "./utils"

export default () => {
  const [isHigh, setHigh] = useStream(quality$)
  const [isFull, setFull] = useStream(fullscreen$)

  const quality = isHigh ? "low" : "high"
  const toggleQuality = () => setHigh(!isHigh)

  const screen = isFull ? "off" : "on"
  const toggleScreen = () => setFull(!isFull)

  return (
    <ul className="util-buttons">
      <li className="util-button util-button__full-screen">
        <img
          src={`/settings-icons/full-screen-${screen}.svg`}
          alt="fullscreen mode"
          onClick={toggleScreen}
        />
      </li>
      <li className="util-button util-button__quality">
        <span onClick={toggleQuality}>set {quality} res</span>
      </li>
    </ul>
  )
}
