import { useEffect, useState } from "react"
import {
  Vector2,
  Animation,
  StandardMaterial,
  Texture,
  Color3,
} from "babylonjs"
import memoize from "memoizee"

import { getButtonShader } from "./button-shader"

export const getXY = ({ pointerX, pointerY }) => ({
  x: pointerX - window.innerWidth / 2,
  y: pointerY - window.innerHeight / 2,
})

export const getInitXY = () => ({
  pointerX: window.innerWidth / 2,
  pointerY: window.innerHeight / 2,
})

export const normalize = ({ x, y }) => ({
  x: new Vector2(x, window.innerWidth).normalize().x,
  y: new Vector2(y, window.innerHeight).normalize().x,
})

export const swap = ({ x, y }) => ({
  x: y,
  y: x,
})

export const nearestPOT = n => 1 << (31 - Math.clz32(n))

export const getEasing = memoize((Ease, mode) => {
  const easingFunction = new Ease()
  easingFunction.setEasingMode(mode)
  return easingFunction
})

export const getAnimation = (name, prop, fps, easing) => {
  const animation = new Animation(
    name,
    prop,
    fps,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_RELATIVE
  )
  animation.setEasingFunction(easing)
  return animation
}

export const animate = (
  mesh,
  target,
  prop,
  val,
  easing,
  duration,
  onComplete = () => {}
) => {
  const anim = Animation.TransitionTo(
    prop,
    val,
    target,
    mesh.getScene(),
    60 / mesh.getScene().getAnimationRatio(),
    getAnimation(
      `animate-${prop}-${mesh.id}`,
      prop,
      60 / mesh.getScene().getAnimationRatio(),
      easing
    ),
    duration,
    onComplete
  )
  anim.disposeOnEnd = true
}

export const getIconMaterialByPath = (path, scene) => {
  const material = new StandardMaterial("icon-material-" + path, scene)
  const tex = new Texture(path, scene)
  material.emissiveTexture = tex
  material.opacityTexture = tex
  material.freeze()
  return material
}

export const getButtonMaterial = memoize(
  (color, scene) => {
    const col3 = Color3.FromHexString(color)
    const material = getButtonShader(col3, scene)
    const emCol = 0.2
    material.emissiveColor = col3.subtract(new Color3(emCol, emCol, emCol))
    material.freeze()
    return material
  },
  { length: 1, primitive: true }
)

export const createTextureGetter = repeat => (path, scene) => {
  const tex = new Texture(path, scene)
  tex.uScale = repeat
  tex.vScale = repeat
  return tex
}

export const removeFromArr = (arr, item) => {
  const idx = arr.indexOf(item)
  if (idx > -1) arr.splice(idx, 1)
}

export const getAnchor = memoize(() => document.createElement("a"))

export const openLink = href => {
  Object.assign(getAnchor(), {
    target: "_blank",
    href,
  }).click()
}

export const useStream = stream$ => {
  const [state, setState] = useState()
  useEffect(() => {
    const subscription = stream$.subscribe(setState)
    return () => subscription.unsubscribe()
  }, [stream$])
  return [state, s => stream$.next(s)]
}

export const lerpXY = (start, end) => {
  const dx = end.x - start.x
  const dy = end.y - start.y
  const rate = 0.07

  return {
    x: start.x + dx * rate,
    y: start.y + dy * rate,
  }
}

export const lerpN = (start, end) => {
  const n = end - start
  const rate = 0.05
  return start + n * rate
}
