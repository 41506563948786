export const sections = {
  home: [
    "Name: ArtËm Gavrysh",
    "occupation: software engineer",
    "passion: interactive user interfaces",
    "platforms: web, mobile",
  ],
  bio: [
    "Born: KIYEV, USSR",
    "Speaks\nEnglish, русский, українська",
    "Education\nBachelor Degree in Computer Science",
    "age: 29",
  ],
  location: [
    "Amsterdam\nNorth Holland > The Netherlands",
    "Europe > Earth",
    "The Solar System > Orion Arm",
    "The Milky Way > The Virgo Supercluster\nThe Universe",
  ],
  contact: [
    "Instagram: @tem_rysh",
    "Twitter: @tem_rysh",
    "Email: artem.gavrysh@gmail.com",
    "LinkedIn: @gavrysh",
  ],
  exp: [
    "8 years of web development",
    "most used libs\nReactJS, ReduxJS, RxJS, NodeJS",
    "responsibilities\nteam lead, tech lead, senior dev",
    "exp-logos",
  ],
  awards: [
    "",
    "awwwards\nmobile of the week x 1\nhonorable mention x 1\nmobile excellence x 1",
    "CSSDA\nUI design x 1\nUX design x 1\nINNOVATION x 1\nWEBSITE OF THE DAY x 1",
    "",
  ],
}
