import { Subject, BehaviorSubject } from "rxjs"
import {
  throttleTime,
  scan,
  map,
  debounceTime,
  distinctUntilChanged,
  filter,
  tap,
  skip,
} from "rxjs/operators"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { getXY, normalize, swap, lerpXY } from "./utils"

// init zoom
export const initZoom$ = new Subject()

// screen hover dolly zoom
export const zoom$ = new Subject().pipe(
  debounceTime(10),
  throttleTime(10),
  distinctUntilChanged()
)
export const zoomIn$ = zoom$.pipe(
  filter(z => z === "in"),
  tap(() => {
    trackCustomEvent({
      category: "zoom",
      action: "zoom-in",
    })
  })
)
export const zoomOut$ = zoom$.pipe(
  filter(z => z === "out"),
  tap(() => {
    trackCustomEvent({
      category: "zoom",
      action: "zoom-out",
    })
  })
)

// pad label
export const labelActive$ = new BehaviorSubject(null)
export const labelMouseover$ = new Subject()
export const labelMouseleave$ = new Subject()

labelActive$
  .pipe(
    skip(1),
    tap(section => {
      trackCustomEvent({
        category: "section",
        action: `section-${section}`,
      })
    })
  )
  .subscribe()

// mousemove
export const mousemove$ = new Subject().pipe(
  map(getXY),
  scan(lerpXY),
  distinctUntilChanged((a, b) => a.x === b.x && a.y === b.y),
  map(normalize),
  map(swap)
)

// meshes
export const meshLoaded$ = new Subject()
export const loadedMeshes$ = new BehaviorSubject({})

meshLoaded$
  .pipe(
    scan((acc, mesh) => {
      acc[mesh.name] = mesh
      return acc
    }, {})
  )
  .subscribe(loadedMeshes$)

// quality
export const quality$ = new BehaviorSubject(true)
quality$
  .pipe(
    skip(1),
    tap(isHigh => {
      trackCustomEvent({
        category: "quality",
        action: isHigh ? "quality-high" : "quality-low",
      })
    })
  )
  .subscribe()

// fullscreen mode
export const fullscreen$ = new BehaviorSubject(false)
fullscreen$
  .pipe(
    skip(1),
    tap(isFull => {
      trackCustomEvent({
        category: "fullscreen",
        action: isFull ? "fullscreen-on" : "fullscreen-off",
      })
    })
  )
  .subscribe()

// mob menu
export const mobMenu$ = new BehaviorSubject(false)
mobMenu$
  .pipe(
    skip(1),
    tap(isOpen => {
      trackCustomEvent({
        category: "mob-menu",
        action: isOpen ? "mob-menu-open" : "mob-menu-closed",
      })
    })
  )
  .subscribe()
