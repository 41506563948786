import React from "react"

import ContactBtns from "./contact-btns"

export default () => (
  <ul className="bio-list">
    <li className="bio-item">
      <h2>name</h2>
      <span>ArtËm Gavrysh</span>
    </li>
    <li className="bio-item">
      <h2>passion</h2>
      <span>user interfaces</span>
    </li>
    <li className="bio-item">
      <h2>occupation</h2>
      <span>software engineer</span>
    </li>
    <li className="bio-item">
      <h2>platforms</h2>
      <span>
        web
        <br />
        mobile
      </span>
    </li>
    <li className="bio-item">
      <h2>location</h2>
      <span>
        amsterdam
        <br />
        the netherlands
      </span>
    </li>
    <li className="bio-item">
      <ContactBtns />
    </li>
    <li className="bio-item"></li>
  </ul>
)
