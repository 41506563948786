import "./styles.css"

import React, { Fragment, useRef, useEffect } from "react"

import Labels from "../components/labels"
import UtilButtons from "../components/util-buttons"
// import Awwwards from "../components/awwwards"

export default () => {
  const ref = useRef()

  useEffect(() => {
    window && import("../components/init").then(({ init }) => init(ref.current))
  }, [ref])

  return (
    <Fragment>
      <canvas ref={ref} />
      <Labels />
      <UtilButtons />
      {/* <Awwwards /> */}
    </Fragment>
  )
}
