import { EasingFunction, QuadraticEase, QuarticEase, Color3 } from "babylonjs"
import anime from "animejs"
import { pipe, of } from "rxjs"
import {
  delay,
  tap,
  takeUntil,
  mapTo,
  switchMap,
  skipUntil,
} from "rxjs/operators"

import { zoomOut$, initZoom$ } from "./streams"
import { getEasing, animate, getButtonMaterial } from "./utils"

export const zoomInPipe = (target, z, fov) =>
  pipe(
    skipUntil(initZoom$),
    switchMap(() =>
      of(1).pipe(
        delay(300),
        takeUntil(zoomOut$),
        mapTo(getEasing(QuarticEase, EasingFunction.EASINGMODE_EASEINOUT)),
        tap(easing => {
          animate(target, target, "fov", fov, easing, 2000)
        }),
        tap(easing => {
          animate(target, target.position, "z", z, easing, 2000)
        })
      )
    )
  )

export const zoomOutPipe = (target, z, fov) =>
  pipe(
    skipUntil(initZoom$),
    delay(100),
    mapTo(getEasing(QuadraticEase, EasingFunction.EASINGMODE_EASEINOUT)),
    tap(easing => {
      animate(target, target, "fov", fov, easing, 1000)
    }),
    tap(easing => {
      animate(target, target.position, "z", z, easing, 1000)
    })
  )

export const animateLabel = (target, { y, alpha, complete }) => {
  const duration = 300
  const easing = getEasing(QuadraticEase, EasingFunction.EASINGMODE_EASEOUT)
  animate(target, target.position, "y", y, easing, duration)
  animate(target, target.material, "alpha", alpha, easing, duration, complete)
}

export const animateButton = (target, color) => {
  // position z
  const tlZ = anime.timeline({
    targets: target.position,
  })
  tlZ.add({
    z: (30 / 1000) * 2,
    easing: "easeInQuad",
    duration: 100,
  })
  tlZ.add({
    z: 0,
    easing: "easeOutBack",
    duration: 300,
  })

  // material color
  const colorState = { val: 0.0 }
  const tlC = anime.timeline({
    targets: colorState,
    update: () => {
      const clr = color.subtract(
        new Color3(colorState.val, colorState.val, colorState.val)
      )
      target.material = getButtonMaterial(clr.toHexString(), target.getScene())
    },
  })
  tlC.add({
    val: 0.7,
    easing: "easeInQuad",
    duration: 100,
  })
  tlC.add({
    val: 0.5,
    easing: "easeOutBack",
    duration: 300,
  })
}

export const animateButtonColorWhite = (target, color) => {
  const colorState = { val: 0.5 }
  anime({
    targets: colorState,
    val: 0.0,
    easing: "easeOutQuad",
    duration: 600,
    update: () => {
      const clr = color.subtract(
        new Color3(colorState.val, colorState.val, colorState.val)
      )
      target.material = getButtonMaterial(clr.toHexString(), target.getScene())
    },
    complete: () => {
      target.material = getButtonMaterial(
        color.toHexString(),
        target.getScene()
      )
    },
  })
}

export const animatePreloaderFadeOut = duration => () =>
  anime({
    targets: ".preloader",
    opacity: 0,
    duration,
    easing: "easeOutQuad",
  })
