import React from "react"

import { mobMenu$ } from "./streams"
import { useStream } from "./utils"

export default () => {
  const [isOpen, setOpen] = useStream(mobMenu$)
  const handleClick = () => setOpen(!isOpen)

  return (
    <div className={`menu-btn ${isOpen ? "open" : ""}`} onClick={handleClick}>
      <div className="menu-bar"></div>
      <div className="menu-bar"></div>
    </div>
  )
}
