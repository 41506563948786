import * as BABYLON from "babylonjs"

export const getButtonShader = (buttonColor, scene) => {
  var nodeMaterial = new BABYLON.NodeMaterial("node", scene)

  // InputBlock
  var position = new BABYLON.InputBlock("position")
  position.setAsAttribute("position")

  // TransformBlock
  var WorldPos = new BABYLON.TransformBlock("WorldPos")
  WorldPos.complementZ = 0
  WorldPos.complementW = 1

  // InputBlock
  var World = new BABYLON.InputBlock("World")
  World.setAsSystemValue(BABYLON.NodeMaterialSystemValues.World)

  // TransformBlock
  var WorldPosViewProjectionTransform = new BABYLON.TransformBlock(
    "WorldPos * ViewProjectionTransform"
  )
  WorldPosViewProjectionTransform.complementZ = 0
  WorldPosViewProjectionTransform.complementW = 1

  // InputBlock
  var ViewProjection = new BABYLON.InputBlock("ViewProjection")
  ViewProjection.setAsSystemValue(
    BABYLON.NodeMaterialSystemValues.ViewProjection
  )

  // VertexOutputBlock
  var VertexOutput = new BABYLON.VertexOutputBlock("VertexOutput")

  // InputBlock
  var color = new BABYLON.InputBlock("color")
  color.value = buttonColor
  color.isConstant = true
  color.visibleInInspector = false

  // MultiplyBlock
  var Multiply = new BABYLON.MultiplyBlock("Multiply")

  // MultiplyBlock
  var Multiply1 = new BABYLON.MultiplyBlock("Multiply")

  // VectorMergerBlock
  var VectorMerger = new BABYLON.VectorMergerBlock("VectorMerger")

  // DivideBlock
  var Divide = new BABYLON.DivideBlock("Divide")

  // SubtractBlock
  var Subtract = new BABYLON.SubtractBlock("Subtract")

  // DotBlock
  var strength = new BABYLON.DotBlock("strength")

  // MultiplyBlock
  var UV = new BABYLON.MultiplyBlock("UV")

  // SubtractBlock
  var Subtract1 = new BABYLON.SubtractBlock("Subtract")

  // InputBlock
  // vUv
  var uv = new BABYLON.InputBlock("uv")
  uv.setAsAttribute("uv")

  // InputBlock
  var InputBlock_243 = new BABYLON.InputBlock("")
  InputBlock_243.value = new BABYLON.Vector2(0.5, 0.5)
  InputBlock_243.isConstant = true
  InputBlock_243.visibleInInspector = false

  // InputBlock
  var resolution = new BABYLON.InputBlock("resolution")
  resolution.value = new BABYLON.Vector2(1, 1)
  resolution.isConstant = true
  resolution.visibleInInspector = false

  // AddBlock
  var weightY = new BABYLON.AddBlock("weight Y")

  // MultiplyBlock
  var Multiply2 = new BABYLON.MultiplyBlock("Multiply")

  // InputBlock
  var radius = new BABYLON.InputBlock("radius")
  radius.value = 0.4
  radius.min = 0
  radius.max = 0
  radius.isBoolean = false
  radius.matrixMode = 0
  radius.animationType = BABYLON.AnimatedInputBlockTypes.None

  // MultiplyBlock
  var Multiply3 = new BABYLON.MultiplyBlock("Multiply")

  // InputBlock
  var glow = new BABYLON.InputBlock("glow")
  glow.value = 2.0
  glow.min = 0
  glow.max = 0
  glow.isBoolean = false
  glow.matrixMode = 0
  glow.animationType = BABYLON.AnimatedInputBlockTypes.None

  // SubtractBlock
  var weightX = new BABYLON.SubtractBlock("weight X")

  // SubtractBlock
  var Subtract2 = new BABYLON.SubtractBlock("Subtract")

  // InputBlock
  var multi = new BABYLON.InputBlock("multi")
  const mlt = 1.8
  multi.value = new BABYLON.Vector3(mlt, mlt, mlt)
  multi.isConstant = true
  multi.visibleInInspector = false

  // FragmentOutputBlock
  var FragmentOutput = new BABYLON.FragmentOutputBlock("FragmentOutput")

  // Connections
  position.output.connectTo(WorldPos.vector)
  World.output.connectTo(WorldPos.transform)
  WorldPos.output.connectTo(WorldPosViewProjectionTransform.vector)
  ViewProjection.output.connectTo(WorldPosViewProjectionTransform.transform)
  WorldPosViewProjectionTransform.output.connectTo(VertexOutput.vector)
  color.output.connectTo(Multiply.left)
  uv.output.connectTo(Subtract1.left)
  InputBlock_243.output.connectTo(Subtract1.right)
  Subtract1.output.connectTo(UV.left)
  resolution.output.connectTo(UV.right)
  UV.output.connectTo(strength.left)
  UV.output.connectTo(strength.right)
  strength.output.connectTo(Subtract.left)
  radius.output.connectTo(Multiply2.left)
  radius.output.connectTo(Multiply2.right)
  Multiply2.output.connectTo(weightY.left)
  radius.output.connectTo(Multiply3.left)
  glow.output.connectTo(Multiply3.right)
  Multiply3.output.connectTo(weightY.right)
  weightY.output.connectTo(Subtract.right)
  Subtract.output.connectTo(Divide.left)
  Multiply2.output.connectTo(weightX.left)
  Multiply3.output.connectTo(weightX.right)
  weightX.output.connectTo(Subtract2.left)
  weightY.output.connectTo(Subtract2.right)
  Subtract2.output.connectTo(Divide.right)
  Divide.output.connectTo(VectorMerger.x)
  Divide.output.connectTo(VectorMerger.y)
  Divide.output.connectTo(VectorMerger.z)
  VectorMerger.xyz.connectTo(Multiply1.left)
  multi.output.connectTo(Multiply1.right)
  Multiply1.output.connectTo(Multiply.right)
  Multiply.output.connectTo(FragmentOutput.rgb)

  // Output nodes
  nodeMaterial.addOutputNode(VertexOutput)
  nodeMaterial.addOutputNode(FragmentOutput)
  nodeMaterial.build()

  return nodeMaterial
}
