import React from "react"

import { mobMenu$ } from "./streams"
import { useStream } from "./utils"
import BioList from "./bio-list"

export default () => {
  const [isOpen] = useStream(mobMenu$)

  return (
    <div className={`menu ${isOpen ? "open" : ""} `}>
      <BioList />
    </div>
  )
}
