import { BehaviorSubject, interval, Scheduler, concat } from "rxjs"
import {
  filter,
  map,
  takeUntil,
  withLatestFrom,
  scan,
  switchMap,
  take,
  distinctUntilChanged,
  delay,
  tap,
  mapTo,
} from "rxjs/operators"

import { lerpN } from "./utils"
import { animatePreloaderFadeOut } from "./animations"

export const progress$ = new BehaviorSubject(0)
export const loaded$ = new BehaviorSubject(false).pipe(filter(Boolean))
export const hidePreloader$ = new BehaviorSubject(false)

loaded$
  .pipe(
    filter(Boolean),
    delay(500),
    tap(animatePreloaderFadeOut(1000)),
    delay(1200),
    mapTo(true)
  )
  .subscribe(hidePreloader$)

export const preloaded$ = new BehaviorSubject({
  remaining: 100,
  total: 100,
}).pipe(
  map(({ remaining, total }) => (total - remaining) / total),
  map(n => n * 100)
)

export const beforeLoaded$ = interval(
  0,
  Scheduler.animationFrameScheduler
).pipe(
  takeUntil(loaded$),
  withLatestFrom(preloaded$, (_, n) => n),
  scan(lerpN),
  map(n => parseInt(n))
)

export const afterLoaded$ = loaded$.pipe(
  withLatestFrom(progress$, (_, n) => n),
  map(n => 100 - n),
  switchMap(n =>
    interval(0, Scheduler.animationFrameScheduler).pipe(
      take(n),
      withLatestFrom(progress$, (_, n) => n),
      map(n => n + 1)
    )
  )
)

concat(beforeLoaded$, afterLoaded$)
  .pipe(distinctUntilChanged())
  .subscribe(progress$)
