import React from "react"

import { useStream } from "./utils"
import { progress$ } from "./progress"

export default () => {
  const [progress] = useStream(progress$)
  return (
    <div className="preloader">
      <span>{progress}</span>
    </div>
  )
}
